.SelectDropdown {
    --bgColor: #fff;
    --highlightColor: #1264a3;
}


.SelectDropdown {
    background: #FFF;
    color: #333;
    font-size: 1.2em;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    max-height: inherit;
    overflow-y: auto;
    padding: 8px 0;
}

.SelectDropdown li > label {
    cursor: pointer;
    width: 100%;
    display: flex;
    font-family: sans-serif;
    box-sizing: border-box;
}

.SelectDropdown label div {
    margin-left: auto;
}

.SelectDropdown {
    transform-origin: top center;
    transition: transform 0.2s;
}

.SelectDropdown li[aria-selected=true] {
    background: var(--highlightColor);
    color: #fff;
}

.SelectDropdown li input[type=radio] {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
    margin: 0;
}