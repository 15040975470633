.Popper {
    overflow: hidden;
    background-color: rgba(248, 248, 248, 1);
    border-radius: 6px;
    box-shadow: rgba(29, 28, 29, 0.13) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 4px 12px 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    z-index: 100;
    max-width: 420px;
    min-width: 260px;
    width: max-content;
    gap: 10px;
    max-height: 250px;
}

.Suggestion {
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
}

.DisplayName {
    font-size: 15px;
}

.LoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
}