.LikeContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #98A2B3;
    border-radius: 6px;
    padding: 8px 6px;
    margin-right: -4px;
}

.LikeContainer:hover {
    background-color: rgba(0, 160, 255, 0.2);
}

.LikeContainer:hover .LikeIcon path {
    stroke: #00A0FF;
}

.LikeButton {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.LikeButton.active .LikeIcon {
    fill: #00A0FF;
}

.LikeButton.active .LikeIcon path {
    stroke: #00A0FF;
}

.LikeButton:disabled {
    cursor: not-allowed;
}

.LikeIcon {
    fill: none;
    transition: all 0.2s ease-in-out;
}

.LikeIcon path {
    stroke: #98A2B3;
}

.LikeCount {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.Popover {
    z-index: 100;
}


.PopoverBody {
    padding-bottom: 10px;
}

.PopoverContent {
    color: #142D63;
    background-color: #ffffff;
    max-width: 180px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 0 20px 0 #062A4033;
    width: fit-content;
    padding: 16px 20px 16px 20px;
}

.LoaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PopoverLikeName {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.PopoverLikeName:not(:first-child) {
    padding-top: 10px;
}

.PopoverOverflowLikes {
    font-size: 12px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
}