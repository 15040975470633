.CommentAddCommentButton {
    display: flex;
    align-self: center;
    text-align: center;
    width: auto;
    z-index: 1;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
    opacity: 1;
    user-select: none;
    padding: 0px 20px;
    font-weight: bold;
    min-height: 36px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 1);
    min-width: 80px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.CommentAddCommentButtonDisabled {
    background-color: rgba(137, 149, 176, 1);
}

.CommentAddCommentButtonActive {
    background-color: rgba(0, 160, 255, 1);
}

.CommentEditorContainer {
    flex-grow: 1;
    border: 1px solid rgb(220, 224, 233);
    border-radius: 5px;
}


.CommentEmojiContainer {
    position: relative;
}

.CommentEmojiContent {
    position: absolute;
    bottom: 30px;
    right: 10px;
}

.CommentMention {
    color: #2e6ef4;
    text-decoration: none;
    vertical-align: baseline;
    display: inline-block;
}

.CommentMention:hover {
    text-decoration: underline;
}

.CommentMentionPopover {
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 16px;
    box-shadow: rgba(29, 28, 29, 0.13) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 4px 12px 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    z-index: 10000;
    max-width: 320px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.CommentMentionPopoverUserAvatar {
    border-radius: 50%;
    width: 46px;
    height: 46px;
}

.CommentMentionPopoverDisplayName {
    font-weight: 900;
}


@media screen and (max-width: 750px) {
    .CommentMention{
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 100%
    }
}